import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics'
import { Helmet } from 'react-helmet'

import 'normalize.css'
import '@fontsource/inria-serif/300.css'
import '@fontsource/inria-serif/400.css'
import '@fontsource/inria-serif/700.css'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'

import '../../styles/index.sass'
import './site-layout.sass'

export function SiteLayout({ page, theme, layout, nav, children, ...props }) {
  const data = useStaticQuery(graphql`
    query {
      wagtail {
        ...SiteMetadata
      }
    }
  `)
  return (
    <div className={`site-layout site-layout--${layout}`} {...props}>
      <Helmet>
        <title>{page && `${page.title} | `}{data.wagtail.site.siteName}</title>
        <meta property="og:url" content={page.url} />
        <meta property="og:title" content={page.seoTitle || page.title} />
        <meta property="og:description" content={page.searchDescription || page.description || page.dek} />
        {page.image && <meta property="og:image" content={page.image.openGraphRendition.url} />}
        {page.image
          ? <meta name="twitter:card" content="summary_large_image" />
          : <meta name="twitter:card" content="summary" />}
        <meta name="twitter:site" content="@NewAmerica" />

        {theme &&
          <body className={`theme--${theme}`} />
        }
      </Helmet>

      <div class="site-layout__nav">
        <div className="site-layout__browser-banner">
          <span role="img" aria-label="warning">⚠️</span> This site is best viewed in a modern browser
          such as <OutboundLink href="https://www.mozilla.org/en-US/firefox/new/">Firefox</OutboundLink> <span role="presentation">⚠️</span> {/* eslint-disable-line jsx-a11y/accessible-emoji */}
        </div>
        {nav}
      </div>


      <main className="site-layout__main">
        {children}
      </main>
    </div>
  )
}

export default SiteLayout